import React, { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'auth'
import { withDenyingAccessForAuthenticated } from '@perry/app/lib/auth'
import { Metadata } from '../components/metadata'

const Auth0LoginPage: FC = () => {
  const { auth } = useSession()
  const { query } = useRouter()
  const returnTo = query['returnTo']

  useEffect(() => {
    if (!auth.isLoading && !auth?.isAuthenticated) {
      auth.login({
        redirectUri: `${window.location.origin}`,
        appState: { returnTo },
      })
    }
  }, [auth?.isLoading, auth?.isAuthenticated])

  return (
    <>
      <Metadata title="meetperry | Login" />
    </>
  )
}

export default withDenyingAccessForAuthenticated(Auth0LoginPage)
